import { Pipe, PipeTransform } from '@angular/core';
import { UtilService } from '@app/_services/util.service';

@Pipe({
  name: 'parseMsAsDuration',
})
export class ParseMsAsDurationPipe implements PipeTransform {
  transform(value: any, showSeconds?: boolean, showNegative?: boolean): any {
    if (typeof value === 'number') {
      return UtilService.parseMS(value * 1000, {
        showSeconds: showSeconds === true,
        showNegative: showNegative === true,
      });
    }
    return null;
  }
}
