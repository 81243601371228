import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'joinString'
})
export class JoinStringPipe implements PipeTransform {
  transform(value: any[], ...args: any[]): string {
    const [delimiter] = args;
    return value.join(delimiter || ',');
  }
}
