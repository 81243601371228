import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClickPreventDirective } from './directives/click.prevent.directive';
import { ClickStopDirective } from './directives/click.stop.directive';
import { ClickNativeDirective } from './directives/click.native.directive';

@NgModule({
  declarations: [ClickPreventDirective, ClickStopDirective, ClickNativeDirective],
  imports: [CommonModule],
  exports: [ClickPreventDirective, ClickStopDirective, ClickNativeDirective],
})
export class AngularExpansionModule {}
