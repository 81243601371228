import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GroupDefaults } from 'timeghost-api';
const defaults = {
  [GroupDefaults.adminGroupId]: 'admin',
  [GroupDefaults.everyoneGroupId]: 'everyone',
};
@Pipe({
  name: 'groupTranslate',
  pure: false,
})
export class GroupTranslatePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}
  transform(value: string, id: string): string {
    if (defaults[id]) return this.translate.instant('workspace.groups.type.' + defaults[id]);
    return value;
  }
}
