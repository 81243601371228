import {
  Directive,
  ElementRef,
  ViewChildren,
  QueryList,
  OnInit,
  AfterContentInit,
  ContentChildren,
  OnDestroy,
  Input,
  forwardRef,
  OnChanges,
} from '@angular/core';
import { FocusMonitor, FocusOrigin } from '@angular/cdk/a11y';
import { distinctUntilChanged, startWith, filter, map, tap } from 'rxjs/operators';
import { combineLatest, Observable, Subject, BehaviorSubject, Subscription, of } from 'rxjs';
import { Logger } from 'timeghost-api';
import { TgInputDirective } from './tg-input.directive';
import { TgLabelDirective } from './tg-label.directive';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[tgContainer],[tg-container]',
  host: {
    class: 'tg-input-container',
    '[class.tg-input-status-error]': 'isError',
    '[class.tg-input-status-disabled]': 'isDisabled',
    '[class.tg-input-label-float]': 'isFilled',
    '[class.tg-input-focused]': 'isFocused',
  },
})
export class TgInputContainerDirective implements OnInit, AfterContentInit, OnDestroy, OnChanges {
  @ContentChildren(forwardRef(() => TgInputDirective), {
    descendants: true,
  })
  inputComponents: QueryList<TgInputDirective> = new QueryList();
  private onDestroy$ = new Subject<void>();
  stateChange = new Subject<void>();
  @Input()
  appearance: 'normal' | 'outline' | 'filled' | 'underline' = 'normal';
  isFocused: boolean = false;
  get hasError() {
    return this.inputComponents?.filter((x) => x.hasError && !x.getControl()?.pristine).length > 0;
  }
  hasErrorInput(c: QueryList<TgInputDirective>) {
    return (
      c?.filter((x) => {
        const ctrl = x.getControl();
        return ctrl?.invalid && !ctrl?.pristine;
      }).length > 0
    );
  }
  hasDisabledInput(c: QueryList<TgInputDirective>) {
    return c?.filter((x) => x.disabled).length > 0;
  }
  get hasDisabled() {
    return (this.inputComponents || new QueryList()).filter((x) => x.hasDisabled).length > 0;
  }
  constructor(private el: ElementRef<HTMLElement>, private fm: FocusMonitor) {}
  ngOnInit() {
    const checkAppearance = (type: string) => this.el.nativeElement.classList.contains(`tg-input-appearance-${type}`);
    const addAppearance = (type: string) => this.el.nativeElement.classList.add(`tg-input-appearance-${type}`);
    if (!checkAppearance(this.appearance)) {
      addAppearance(this.appearance);
    }
  }
  ngOnChanges() {
    this.stateChange.next();
  }
  private monitorInput(inputs: QueryList<TgInputDirective>) {
    return inputs
      .filter((x) => !!x?.getElement())
      .map((x) => {
        return this.fm
          .monitor(x.getElement() as ElementRef<HTMLElement>, false)
          .pipe(distinctUntilChanged())
          .subscribe((fo: FocusOrigin) => {
            this.isFocused = !!fo;
          });
      });
  }
  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
  private statusChange: Subscription;
  ngAfterContentInit() {
    (this.inputComponents.changes as Observable<QueryList<TgInputDirective>>)
      .pipe(
        startWith(this.inputComponents),
        filter((x: QueryList<TgInputDirective>) => x?.length > 0)
      )
      .subscribe((x: QueryList<TgInputDirective>) => {
        if (this.statusChange) {
          this.statusChange.unsubscribe();
        }
        this.monitorInput(x);
        this.checkFilled(x);
        this.checkError(x);
        this.checkDisabled(x);
        this.statusChange = combineLatest(
          x
            .filter((y) => !!y.getControl())
            .map((y) => [y.getControl().statusChanges, y.getControl().valueChanges, y.stateChanges.asObservable()])
            .reduce((l, r) => [...l, ...r], [])
        ).subscribe(() => {
          this.checkFilled(x);
          this.checkError(x);
          this.checkDisabled(x);
        });
      });
  }
  isError: boolean = false;
  checkError(x: QueryList<TgInputDirective>) {
    this.isError = this.hasErrorInput(x);
  }
  isDisabled: boolean = false;
  checkDisabled(x: QueryList<TgInputDirective> | TgInputDirective[]) {
    this.isDisabled = x.filter((c) => c.disabled)?.length > 0;
  }
  isFilled: boolean = false;
  checkFilled(x: QueryList<TgInputDirective>) {
    this.isFilled =
      x.filter((y) =>
        y?.getControl()
          ? (y.getControl()?.value as string)?.length > 0
          : y.getElement<HTMLInputElement>()?.nativeElement?.value?.length > 0
      ).length > 0;
  }
}
const log = new Logger(TgInputContainerDirective.name);
