import { Pipe, PipeTransform, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { format } from 'date-fns-tz/esm';
import { calculateLocale, DateFnsConfigurationService, DateFnsInputDate } from 'ngx-date-fns';
import { Subscription } from 'rxjs';

@Pipe({
  name: 'dfnsFormatTz',
  pure: false
})
export class DfnsFormatTzPipe implements PipeTransform, OnDestroy {
  private localeChanged$: Subscription;

  constructor(public config: DateFnsConfigurationService, public cd: ChangeDetectorRef) {
    this.localeChanged$ = this.config.localeChanged.subscribe(_ => this.cd.markForCheck());
  }

  ngOnDestroy(): void {
    this.localeChanged$.unsubscribe();
  }

  transform(
    date: DateFnsInputDate,
    dateFormat: string,
    timeZone: string,
    options?: {
      locale?: Locale;
      weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
      firstWeekContainsDate?: number;
      useAdditionalWeekYearTokens?: boolean;
      useAdditionalDayOfYearTokens?: boolean;
    }
  ): string {
    return format(date, dateFormat, { timeZone, ...calculateLocale(options, this.config) });
  }
}
