import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceString',
})
export class ReplaceStringPipe implements PipeTransform {
  transform(value: string, find: string | RegExp, replaceWith?: string): unknown {
    return value.replace(find, replaceWith);
  }
}
