import { Directive, ElementRef, ContentChildren, QueryList, OnDestroy, forwardRef } from '@angular/core';
import { TgHintDirective } from './tg-hint.directive';
import { takeUntil, filter, map, distinctUntilChanged, startWith, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Logger } from 'timeghost-api';
const log = new Logger('TgFormfieldDirective');
@Directive({
  selector: '[tg-form-field],[tgFormField],[tgformfield]',
  host: {
    class: 'tg-form-field',
  },
})
export class TgFormfieldDirective implements OnDestroy {
  readonly onDestroy = new Subject<void>();
  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
  @ContentChildren(forwardRef(() => TgHintDirective), {
    descendants: true,
  })
  hintComponents: QueryList<TgHintDirective> = new QueryList<TgHintDirective>();
  constructor(private el: ElementRef<HTMLElement>) {}
}
