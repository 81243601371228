import { Directive, ElementRef, Self, Inject, Optional, Input, OnChanges, OnInit, DoCheck } from '@angular/core';
import { NgControl } from '@angular/forms';
import { TgInputContainerDirective } from './tg-input-container.directive';
import { Logger } from 'timeghost-api';
import { Subject } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ErrorStateMatcher } from '@angular/material/core';
import { MAT_LEGACY_INPUT_VALUE_ACCESSOR as MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/legacy-input';

@Directive({
  selector: '[tgInput],[tg-input]',
  host: {
    class: 'tg-input',
    '[disabled]': 'disabled',
    '[required]': 'required',
    '(blur)': '_focusChanged(false)',
    '(focus)': '_focusChanged(true)',
    '(input)': '_onInput()',
  },
})
export class TgInputDirective implements OnChanges, OnInit, DoCheck {
  protected _previousNativeValue: any;
  private _inputValueAccessor: { value: any };

  constructor(
    private el: ElementRef<HTMLInputElement>,
    private container: TgInputContainerDirective,
    @Optional() @Self() @Inject(MAT_INPUT_VALUE_ACCESSOR) inputValueAccessor: any,
    @Optional()
    @Self()
    private control?: NgControl,
  ) {
    this._inputValueAccessor = inputValueAccessor || el;

    this._previousNativeValue = this.value;
  }
  /**
   * Implemented as part of MatFormFieldControl.
   * @docs-private
   */
  @Input()
  get value(): string {
    return this._inputValueAccessor.value;
  }
  set value(value: string) {
    if (value !== this.value) {
      this._inputValueAccessor.value = value;
      this.stateChanges.next();
    }
  }
  focused: boolean = false;
  /** Whether the element is readonly. */
  @Input()
  get readonly(): boolean {
    return this._readonly;
  }
  set readonly(value: boolean) {
    this._readonly = coerceBooleanProperty(value);
  }
  private _readonly = false;

  ngOnChanges() {
    this.stateChanges.next();
  }
  readonly stateChanges: Subject<void> = new Subject<void>();
  getElement<T>(): ElementRef<T> {
    return this.el as any as ElementRef<T>;
  }
  getControl() {
    return this.control;
  }
  get hasError() {
    return (
      !!this.control &&
      this.control.dirty === true &&
      this.control.errors &&
      Object.keys(this.control.errors).length > 0
    );
  }
  /** Callback for the cases where the focused state of the input changes. */
  _focusChanged(isFocused: boolean) {
    if (isFocused !== this.focused && (!this.readonly || !isFocused)) {
      this.focused = isFocused;
      this.stateChanges.next();
    }
  }
  _onInput() {
    // This is a noop function and is used to let Angular know whenever the value changes.
    // Angular will run a new change detection each time the `input` event has been dispatched.
    // It's necessary that Angular recognizes the value change, because when floatingLabel
    // is set to false and Angular forms aren't used, the placeholder won't recognize the
    // value changes and will not disappear.
    // Listening to the input event wouldn't be necessary when the input is using the
    // FormsModule or ReactiveFormsModule, because Angular forms also listens to input events.
  }
  ngOnInit(): void {
    this.disabled = this.hasDisabled;
    this.stateChanges.next();
  }

  ngDoCheck() {
    // We need to dirty-check the native element's value, because there are some cases where
    // we won't be notified when it changes (e.g. the consumer isn't using forms or they're
    // updating the value using `emitEvent: false`).
    this._dirtyCheckNativeValue();
  }
  protected _dirtyCheckNativeValue() {
    const newValue = this.el.nativeElement.value;

    if (this._previousNativeValue !== newValue) {
      this._previousNativeValue = newValue;
      this.stateChanges.next();
    }
    if (this.el?.nativeElement?.disabled && this.control?.disabled) {
      this.stateChanges.next();
    }
  }
  /**
   * Implemented as part of MatFormFieldControl.
   * @docs-private
   */
  @Input()
  get disabled(): boolean {
    if (this.control && this.control.disabled !== null) {
      return this.control.disabled;
    }
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);

    // Browsers may not fire the blur event if the input is disabled too quickly.
    // Reset from here to ensure that the element doesn't become stuck.
    if (this.focused) {
      this.focused = false;
      this.stateChanges.next();
    }
  }
  protected _disabled = false;
  get hasDisabled() {
    return !!this.control?.disabled || this.el?.nativeElement?.hasAttribute('disabled');
  }
}
const log = new Logger(TgInputDirective.name);
