import {
  Directive,
  EventEmitter,
  Output,
  Renderer2,
  ElementRef,
  OnDestroy,
  OnInit,
  HostListener,
  Optional,
} from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';

@Directive({
  selector: '[click.native]',
})
export class ClickNativeDirective {
  @Output('click.native') clickEv = new EventEmitter();

  constructor(
    @Optional() private routerLink: RouterLink,
    @Optional() private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}
  @HostListener('click', ['$event'])
  async onHostElementClick(ev: Event) {
    ev.preventDefault();
    ev.stopPropagation();
    if (this.activatedRoute) {
      const route = this.activatedRoute.snapshot;
      await this.router.navigate(route.url, route);
    }
    this.clickEv.emit(...arguments);
  }
}
