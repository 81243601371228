import { Pipe, PipeTransform } from '@angular/core';
import type { ValueIteratee } from 'lodash';
import uniqBy from 'lodash-es/uniqBy';

@Pipe({
  name: 'uniqBy',
  standalone: true,
})
export class UniqByPipe implements PipeTransform {
  transform<T extends Array<{}>, R = T | null>(value: T, iteratee: ValueIteratee<T[0]>): R {
    return ((value?.length && uniqBy(value, iteratee)) || []) as R;
  }
}
