import { Pipe, PipeTransform } from '@angular/core';
import { UserSettings, UserSettingsQuery } from 'timeghost-api';

@Pipe({
  name: 'customCurrency',
})
export class CustomCurrencyPipe implements PipeTransform {
  constructor(private userSettings: UserSettingsQuery) {}
  transform(value: number, locale?: string): string {
    const user = this.userSettings.getValue();
    const newValue = value?.toLocaleString(locale || user.settings?.languageSetting, {
      minimumFractionDigits: 2,
    });
    if (!newValue) return null;
    return `${user.workspace?.settings?.currency || '$'} ${newValue}`;
  }
}
