import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'flat',
})
export class ArrayFlatPipe implements PipeTransform {
  transform<T extends Array<T>>(value: T, depth: number = 1): T[] {
    return (value as any[]).flat(depth);
  }
}
