import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRightSideSheetComponent } from './mat-right-side-sheet.component';
import { MatRightSideSheet, MAT_SHEET_DEFAULT_OPTIONS } from './mat-right-side-sheet';
import {
  MatLegacyDialogConfig as MatDialogConfig,
  MatLegacyDialogContainer as MatDialogContainer,
  MAT_LEGACY_DIALOG_SCROLL_STRATEGY_PROVIDER as MAT_DIALOG_SCROLL_STRATEGY_PROVIDER,
} from '@angular/material/legacy-dialog';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { MatCommonModule } from '@angular/material/core';

@NgModule({
  declarations: [MatRightSideSheetComponent],
  imports: [OverlayModule, PortalModule, MatCommonModule],
  providers: [
    MatRightSideSheet,
    MAT_DIALOG_SCROLL_STRATEGY_PROVIDER,
    {
      provide: MAT_SHEET_DEFAULT_OPTIONS,
      useValue: <MatDialogConfig>{
        height: '100%',
        maxHeight: '100%',
        panelClass: 'mat-sheet-panel',
        hasBackdrop: true,
        position: {
          right: '0',
        },
      },
    },
  ],
})
export class MatRightSideSheetModule {}
