import { ChangeDetectorRef, OnInit, Pipe, PipeTransform } from '@angular/core';
import { timeFormatToDuration } from '@app/_helpers/utils';
import { UtilService } from '@app/_services/util.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OptionsWithTZ } from 'date-fns-tz';
import { FormatPipe as DfnsFormatPipe } from 'ngx-date-fns';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { ApplicationSettingsQuery, UserSettingsQuery } from 'timeghost-api';

@UntilDestroy()
@Pipe({
  name: 'formatDate',
})
export class FormatDatePipe implements PipeTransform, OnInit {
  constructor(
    private appSettingsQuery: ApplicationSettingsQuery,
    private dateFnsFormat: DfnsFormatPipe,
    private userSettingsQuery: UserSettingsQuery,
    private cdRef: ChangeDetectorRef,
  ) {}

  get defaultFormat() {
    return this.userSettingsQuery.getValue().settings?.dateFormat ?? 'PP';
  }
  get defaultTimezone() {
    return (
      this.userSettingsQuery.getValue().settings?.timeZone ?? Intl.DateTimeFormat?.()?.resolvedOptions?.()?.timeZone
    );
  }
  ngOnInit(): void {
    this.appSettingsQuery
      .select()
      .pipe(
        map((x) => x?.workspace?.dateFormat),
        distinctUntilChanged(),
        untilDestroyed(this),
      )
      .subscribe(() => this.cdRef.markForCheck());
  }
  transform(
    _date: string | number | Date,
    _format: StringLiteral<'date' | 'time' | 'datetime'>,
    _options?: OptionsWithTZ,
    dateTimezone?: string,
  ): string {
    const date = new Date(_date);
    const options: OptionsWithTZ = Object.assign(
      <OptionsWithTZ>{ ...this.dateFnsFormat.config, timeZone: dateTimezone ?? this.defaultTimezone },
      _options || {},
    );
    if (_format === 'duration' && typeof _date === 'string')
      return UtilService.parseMS(timeFormatToDuration(_date) * 1000, {
        showNegative: false,
        showSeconds: false,
      });
    const enableDate = !_format || _format === 'P' || _format === 'PP' || _format === 'date' || _format === 'datetime';
    const enableTime = _format === 'p' || _format === 'pp' || _format === 'time' || _format === 'datetime';
    const enableTimeTz = _format === 'tz:time';
    const user = this.userSettingsQuery.getValue();
    const timeFormat = enableTime && user.settings?.timeFormat24h ? 'HH:mm' : 'hh:mm a';
    if (!enableTime && !enableDate && _format) return this.dateFnsFormat.transform(date, _format);
    const dtFormat = [enableDate ? this.defaultFormat : null, enableTime || enableTimeTz ? timeFormat : null]
      .filter(Boolean)
      .join(' ');
    return this.dateFnsFormat.transform(date, dtFormat, options);
  }
}
