import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'clamp',
})
export class ClampPipe implements PipeTransform {
  transform(value: number, min: number, max: number = value): number {
    return value.clamp(min, max);
  }
}
